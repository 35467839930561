.line {
  width: 100%;
  margin-top: 0.3rem;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.gonggao {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.gonggao .title {
  margin-top: 0.3rem;
  margin-left: 0.4rem;
  font-size: 0.32rem;
}
.gonggao .time {
  font-size: 0.3rem;
  margin-top: 2px;
  margin-left: 0.4rem;
}
.gonggao .name {
  font-size: 0.3rem;
  margin-top: 0.5rem;
  margin-left: 0.4rem;
}
.gonggao .message {
  width: 335px;
  margin: 0 auto;
  margin-top: 0.16rem;
  font-size: 0.28rem;
  color: #9a9a9a;
}
