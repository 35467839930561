



































.line {
  width: 100%;
  margin-top: 0.3rem;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.gonggao {
  display: flex;
  width: 100%;
  flex-direction: column;
  .title {
    margin-top: 0.3rem;
    margin-left: 0.4rem;
    font-size: 0.32rem;
  }
  .time {
    font-size: 0.3rem;
    margin-top: 2px;
    margin-left: 0.4rem;
  }
  .name {
    font-size: 0.3rem;
    margin-top: 0.5rem;
    margin-left: 0.4rem;
  }
  .message {
    width: 335px;
    margin: 0 auto;
    margin-top: 0.16rem;
    font-size: 0.28rem;
    color: #9a9a9a;
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;